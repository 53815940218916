<script lang="ts" setup>
import { Head, Link, useForm } from "@inertiajs/vue3"
import AuthenticationCard from "@/Components/AuthenticationCard.vue"
import { SButton, SInput, SCheckbox, SFormGroup, SDivider } from "stellar-ui"

defineProps({
    canResetPassword: Boolean,
    status: String,
})

const form = useForm({
    email: "",
    password: "",
    remember: false,
})

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    })
}
</script>

<template>
    <Head title="Log in" />

    <AuthenticationCard>
        <template #title>Welcome back</template>

        <template #description>Sign in to your account</template>

        <template #header>
            <SButton
                :to="route('auth.redirect', 'google')"
                icon="icon-[flat-color-icons--google]"
                color="white"
                variant="solid"
                block
                class="w-full mt-8"
                label="Continue with Google" />

            <SDivider
                class="mt-4"
                label="OR" />
        </template>

        <div
            v-if="status"
            class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <SFormGroup
                label="Email"
                :error="form.errors.email">
                <SInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="username" />
            </SFormGroup>

            <SFormGroup
                label="Password"
                class="mt-4"
                :error="form.errors.password">
                <SInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="current-password" />
            </SFormGroup>

            <div class="block mt-4">
                <label class="flex items-center">
                    <SCheckbox
                        v-model:checked="form.remember"
                        name="remember" />

                    <span class="ml-2 text-sm text-gray-600 dark:text-gray-300 dark:text-white">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm dark:text-white dark:hover:text-gray-200 text-gray-600 dark:text-gray-300 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Forgot your password?
                </Link>

                <SButton
                    type="submit"
                    class="ml-4"
                    :loading="form.processing">
                    Log in
                </SButton>
            </div>
        </form>

        <template #footer>
            <div class="mt-8">
                <span class="dark:text-white">New to GetCopy?</span>

                <Link
                    :href="route('register')"
                    class="mt-8 ml-1 text-primary-500 hover:text-primary-600 hover:underline"
                    >Sign Up
                </Link>
            </div>
        </template>
    </AuthenticationCard>
</template>
